import React, {Component} from 'react';
import APIService from '../API';
import moment from 'moment';

const amountOptions = [
  15,
  25,
  50,
  75,
  100
];
const deliveryTimeOptions = [
  { text: '12:00 am', value: '00:00'},
  { text: '1:00 am', value: '01:00'},
  { text: '2:00 am', value: '02:00'},
  { text: '3:00 am', value: '03:00'},
  { text: '4:00 am', value: '04:00'},
  { text: '5:00 am', value: '05:00'},
  { text: '6:00 am', value: '06:00'},
  { text: '7:00 am', value: '07:00'},
  { text: '8:00 am', value: '08:00'},
  { text: '9:00 am', value: '09:00'},
  { text: '10:00 am', value: '10:00'},
  { text: '11:00 am', value: '11:00'},
  { text: '12:00 pm', value: '12:00'},
  { text: '1:00 pm', value: '13:00'},
  { text: '2:00 pm', value: '14:00'},
  { text: '3:00 pm', value: '15:00'},
  { text: '4:00 pm', value: '16:00'},
  { text: '5:00 pm', value: '17:00'},
  { text: '6:00 pm', value: '18:00'},
  { text: '7:00 pm', value: '19:00'},
  { text: '8:00 pm', value: '20:00'},
  { text: '9:00 pm', value: '21:00'},
  { text: '10:00 pm', value: '22:00'},
  { text: '11:00 pm', value: '23:00'},
];
const deliveryTimeZones = [
  { text: 'Eastern', value: 'America/New_York'},
  { text: 'Central', value: 'America/Chicago'},
  { text: 'Mountain', value: 'America/Denver'},
  { text: 'Pacific', value: 'America/Los_Angeles'},
];

export const GiftCardContext = React.createContext();

export class GiftCardProvider extends Component {

  state = {
    cardDesigns: [],

    selectedCardDesign: {},
    selectedAmountOption: 25,
    hasSelectedCustomAmount: false,
    customAmount: null,
    recipientFirstName: "",
    recipientLastName: "",
    recipientEmail: "",
    message: "",
    senderFirstName: "",
    senderLastName: "",
    scheduleDeliveryDayTime: new moment().add(1, 'hours'),
    immediateDelivery: true,
    timeOfDay: new moment().add(1, 'hours').startOf('hour').format('HH:mm'),
    timeZone: deliveryTimeZones.find(v => v.value === moment.tz.guess()) ? moment.tz.guess() : "America/New_York",

    billingCardNumber: "",
    billingCVV: "",
    billingMonth: "",
    billingYear: "",
    billingFirstName: "",
    billingLastName: "",
    billingEmail: "",
    billingPhone: "",
    billingAddress: "",
    billingAddress2: "",
    billingCity: "",
    billingState: "",
    billingZip: "",

    purchaseError: null,
    errorRefId: null,

    approvedAmount: "",
    confirmationCode: "",

    inquiryCardNumber: "",
    inquiryCardPin: "",
    inquiryAmount: "",

    isPromoActive: false,
    promoName: "",
    promoEndDate: "",
    promoDescription: "",
    sendPromotionToRecipient: false,

    isFetchingCardDesigns: false,
    isFetchingPromoPeriods: false,
    isPostingCard: false,
    isFetchingCardInquiry: false,
    isValidInquiry: false,
    isInvalidInquiry: false,
    hasInquiryBeenAttempted: false,
  };

  serverUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

  componentDidMount() {
    this.fetchCardDesigns();
    this.getPromoPeriod();
  }

  updateContext = (object) => {
    this.setState(object)
  };

  resetPurchaseInfo = () => {
    this.setState({
      selectedCardDesign: {},
      cardAmount: null,
      recipientFirstName: "",
      recipientLastName: "",
      recipientEmail: "",
      message: "",
      senderFirstName: "",
      senderLastName: "",
      scheduleDeliveryDayTime: new moment(),
      immediateDelivery: false,

      billingCardNumber: "",
      billingCVV: "",
      billingMonth: "",
      billingYear: "",
      billingFirstName: "",
      billingLastName: "",
      billingEmail: "",
      billingPhone: "",
      billingAddress: "",
      billingAddress2: "",
      billingCity: "",
      billingState: "",
      billingZip: "",
    })
  }

  fetchCardDesigns = () => {
    this.setState({isFetchingCardDesigns: true})
    APIService.getActiveCardDesigns()
      .then(cardDesigns => {
        this.setState({
          cardDesigns: cardDesigns,
          isFetchingCardDesigns: false
        })
      })
  }

  getPromoPeriod = () => {
    this.setState({isFetchingPromoPeriods: true})
    APIService.getPromoPeriod()
      .then(promoPeriod => {
        this.setState({
          isPromoActive: promoPeriod.found,
          promoName: promoPeriod.name,
          promoEndDate: new moment(promoPeriod.endDate),
          promoDescription: promoPeriod.description,
          isFetchingPromoPeriods: false
        })
      })
  }

  postGiftCardPurchase = (history, relativePath) => {
    this.setState({isPostingCard: true})
    APIService.createGiftCard(this.state)

      .then(response => {
        if(response.ok) {
          return response.json()
        } else {
          switch(response.status) {
            case 400:
              // We want the user to retry, user has provided bad info.
              return response.json().then(json => {
                this.setState({
                  isPostingCard: false,
                  purchaseError: json.message,
                  errorRefId: json.reference
                });
                // stay on page but present an error ?Modal?
                return null;
              })
              break
            default:
              // Redirect user to Donatos Support
              return response.json().then(json => {
                this.setState({
                  isPostingCard: false,
                  purchaseError: json.message,
                  errorRefId: json.reference
                });
                this.resetPurchaseInfo();
                history.push(relativePath + '/error');
                return null;
              })
              break
          }
        }
      })

      .then(json => {
        if(json) {
          this.setState({
            confirmation: json,
            isPostingCard: false,
          })
          this.resetPurchaseInfo();
          history.push(relativePath + '/order-confirmation');
        }
      })

      .catch((error) => {
        // Code issues, Network issues, etc.
        this.setState({
          isPostingCard: false,
        })
        this.resetPurchaseInfo();
        history.push(relativePath + '/error');
      })
  };

  getInquiry = () => {
    this.setState({
      isFetchingCardInquiry: true,
      isValidInquiry: false,
      isInvalidInquiry: false,
      hasInquiryBeenAttempted: false,
    })
    APIService.getInquiry(this.state)
      .then((json) => {
        this.setState({
          inquiryAmount: json.balance,
          isValidInquiry: true,
        })
      })
      .catch(err => {
        this.setState({isInvalidInquiry: true})
        console.error(err);
      })
      .finally(() => {
        this.setState({
          isFetchingCardInquiry: false,
          hasInquiryBeenAttempted: true
        })
      })
  }

  validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email)
  }

  validateCardDetail = (key) => {
    const value = this.state[key];
    switch (key) {
    case "recipientEmail":
      if (!this.validateEmail(value)) return false;
      break;
    case "cardAmount":
      if(this.state.hasSelectedCustomAmount) {
        if (
          !this.state.customAmount ||
          Number(this.state.customAmount) < 5 ||
          Number(this.state.customAmount) > 100
        ) {
          return false;
        }
      } else if (!this.state.selectedAmountOption) {
        return false;
      }
      break;
    case "selectedCardDesign":
      if(!this.state.selectedCardDesign._id) return false;
      break;
    default:
      if(value === undefined || value === null || value === "") return false;
      break;
    }
    return true;
  }

  validateCardDetailForm = () => {
    let isFormValid = true;
    [
      "cardAmount",
      "recipientFirstName",
      "recipientLastName",
      "recipientEmail",
      "senderFirstName",
      "senderLastName",
      "selectedCardDesign"
    ].forEach((key) => {
      isFormValid = this.validateCardDetail(key) ? isFormValid : false
    })
    return isFormValid;
  };

  luhn_checksum = (code) => {
    var len = code.length
    var parity = len % 2
    var sum = 0
    for (var i = len-1; i >= 0; i--) {
        var d = parseInt(code.charAt(i))
        if (i % 2 == parity) { d *= 2 }
        if (d > 9) { d -= 9 }
        sum += d
    }
    return sum % 10 == 0
  }

  validatePaymentFormItem = (key) => {
    let isItemValid = true;
    const value = this.state[key];
    if(value === undefined || value === null || value === "") isItemValid = false;
    if(key === "billingEmail" && !this.validateEmail(value)) isItemValid = false;
    if(key === "billingCardNumber" && !this.luhn_checksum(value)) isItemValid = false;
    return isItemValid;
  }

  validatePaymentForm = () => {
    let isFormValid = true;
    [
      "billingCardNumber",
      "billingCVV",
      "billingMonth",
      "billingYear",
      "billingFirstName",
      "billingLastName",
      "billingEmail",
      "billingPhone",
      "billingAddress",
      "billingCity",
      "billingState",
      "billingZip",
    ].forEach((key) => {
      if(!this.validatePaymentFormItem(key)) {
        isFormValid = false;
      }
    })
    return isFormValid;
  };

  validateInquiryForm = () => {
    let isFormValid = true;
    [
      "inquiryCardNumber",
      "inquiryCardPin",
    ].forEach((key) => {
      const value = this.state[key];
      if(value === undefined || value === null || value === "") isFormValid = false;
    })
    return isFormValid;
  }

  render() {
    return (
      <GiftCardContext.Provider
        value={{
          state: this.state,
          amountOptions: amountOptions,
          deliveryTimeOptions: deliveryTimeOptions,
          deliveryTimeZones: deliveryTimeZones,
          updateContext: this.updateContext,
          postGiftCardPurchase: this.postGiftCardPurchase,
          validateCardDetailForm: this.validateCardDetailForm,
          validateCardDetail: this.validateCardDetail,
          validatePaymentFormItem: this.validatePaymentFormItem,
          validatePaymentForm: this.validatePaymentForm,
          validateEmail: this.validateEmail,
          validateInquiryForm: this.validateInquiryForm,
          getInquiry: this.getInquiry
        }}
      >
        {this.props.children}
      </GiftCardContext.Provider>
    )
  }
};
