import React, {Component} from "react";
import {withRouter} from "react-router";
import {Route, Switch} from "react-router-dom";
import {GiftCardProvider} from "../Context/GiftCardContext";
import Home from "../Views/Home";
import CardDetails from "../Views/CardDetails";
import ReviewPage from "../Views/Review";
import OrderConfirmation from "../Views/OrderConfirmation";
import BalanceInquiry from "../Views/BalanceInquiry";
import ErrorHandling from "../Views/ErrorHandling";

class GiftCardController extends Component {

  render = () => {

    let relativePath = this.props.match.path;

    return (
      <GiftCardProvider>
        <Switch>
          <Route
            path={relativePath}
            exact
            render={() =>
              <Home
                relativePath={relativePath}
              />
            }
          />
          <Route
            path={relativePath + "/card-details"}
            render={() =>
              <CardDetails
                relativePath={relativePath}
              />
            }
          />
          <Route
            path={relativePath + "/review"}
            render={() =>
              <ReviewPage
                relativePath={relativePath}

              />
            }
          />
          <Route
            path={relativePath + "/order-confirmation"}
            render={() =>
              <OrderConfirmation
                relativePath={relativePath}
              />
            }
          />
          <Route
            path={relativePath + "/balance-inquiry"}
            render={() =>
              <BalanceInquiry
                relativePath={relativePath}
              />
            }
          />
          <Route
            path={relativePath + "/error"}
            render={() =>
              <ErrorHandling
                relativePath={relativePath}
              />
            }
          />
        </Switch>
      </GiftCardProvider>
    )
  };
};

export default withRouter(GiftCardController);
