import React, {Component} from "react";
import { Button, Card, Dropdown, Form, Header, Input, Label, Segment } from "semantic-ui-react";
import {withRouter} from "react-router";
import {GiftCardContext} from "../Context/GiftCardContext";
import moment from 'moment-timezone';


const availableStates = [
  { text: 'Alaska', value: 'Alaska'},
  { text: 'Alabama', value: 'Alabama'},
  { text: 'Arizona', value: 'Arizona'},
  { text: 'Arkansas', value: 'Arkansas'},
  { text: 'California', value: 'California'},
  { text: 'Colorado', value: 'Colorado'},
  { text: 'Connecticut', value: 'Connecticut'},
  { text: 'Delaware', value: 'Delaware'},
  { text: 'Florida', value: 'Florida'},
  { text: 'Georgia', value: 'Georgia'},
  { text: 'Hawaii', value: 'Hawaii'},
  { text: 'Idaho', value: 'Idaho'},
  { text: 'Illinois', value: 'Illinois'},
  { text: 'Indiana', value: 'Indiana'},
  { text: 'Iowa', value: 'Iowa'},
  { text: 'Kansas', value: 'Kansas'},
  { text: 'Kentucky', value: 'Kentucky'},
  { text: 'Luisiana', value: 'Luisiana'},
  { text: 'Maine', value: 'Maine'},
  { text: 'Maryland', value: 'Maryland'},
  { text: 'Massachusetts', value: 'Massachusetts'},
  { text: 'Michigan', value: 'Michigan'},
  { text: 'Minnesota', value: 'Minnesota'},
  { text: 'Mississippi', value: 'Mississippi'},
  { text: 'Missouri', value: 'Missouri'},
  { text: 'Montana', value: 'Montana'},
  { text: 'Nebraska', value: 'Nebraska'},
  { text: 'Nevada', value: 'Nevada'},
  { text: 'New Hampshire', value: 'New Hampshire'},
  { text: 'New Jersey', value: 'New Jersey'},
  { text: 'New Mexico', value: 'New Mexico'},
  { text: 'New York', value: 'New York'},
  { text: 'North Carolina', value: 'North Carolina'},
  { text: 'North Dakota', value: 'North Dakota'},
  { text: 'Ohio', value: 'Ohio'},
  { text: 'Oklahoma', value: 'Oklahoma'},
  { text: 'Oregon', value: 'Oregon'},
  { text: 'Pennsylvania', value: 'Pennsylvania'},
  { text: 'Rhode Island', value: 'Rhode Island'},
  { text: 'South Carolina', value: 'South Carolina'},
  { text: 'South Dakota', value: 'South Dakota'},
  { text: 'Tennessee', value: 'Tennessee'},
  { text: 'Texas', value: 'Texas'},
  { text: 'Utah', value: 'Utah'},
  { text: 'Vermont', value: 'Vermont'},
  { text: 'Virginia', value: 'Virginia'},
  { text: 'Washington', value: 'Washington'},
  { text: 'West Virginia', value: 'West Virginia'},
  { text: 'Wisconsin', value: 'Wisconsin'},
  { text: 'Wyoming', value: 'Wyoming'},
];
const availableMonths = [
  { text: '01 - January', value: "01"},
  { text: '02 - February', value: "02"},
  { text: '03 - March', value: "03"},
  { text: '04 - April', value: "04"},
  { text: '05 - May', value: "05"},
  { text: '06 - June', value: "06"},
  { text: '07 - July', value: "07"},
  { text: '08 - August', value: "08"},
  { text: '09 - September', value: "09"},
  { text: '10 - October', value: "10"},
  { text: '11 - November', value: "11"},
  { text: '12 - December', value: "12"},
];
const availableYears = [
  { text: '2020', value: "20"},
  { text: '2021', value: "21"},
  { text: '2022', value: "22"},
  { text: '2023', value: "23"},
  { text: '2024', value: "24"},
  { text: '2025', value: "25"},
  { text: '2026', value: "26"},
  { text: '2027', value: "27"},
  { text: '2028', value: "28"},
  { text: '2029', value: "29"},
  { text: '2030', value: "30"},
  { text: '2031', value: "31"},
  { text: '2032', value: "32"},
  { text: '2033', value: "33"},
  { text: '2034', value: "34"},
  { text: '2035', value: "35"},
  { text: '2036', value: "36"},
  { text: '2037', value: "37"},
  { text: '2038', value: "38"},
  { text: '2039', value: "39"},
  { text: '2040', value: "40"},
];

class ReviewPage extends Component {

  componentDidMount() {
    this.scrollToTop();
  };

  scrollToTop = () => {
    window.scrollTo(0, 0)
  };

  contextRef = React.createRef();

  apiURI = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

  formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    document.querySelector("#tel-national").addEventListener("keypress", function (evt) {
      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault();
      }
  	});

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    return null
  };

  render = () => {
    return (
      <GiftCardContext.Consumer>
        {(context) => (
          <Segment className="pageSegment">
            <Form
              autocomplete="on"
              autofill="on"
              id="mainPaymentForm"
              className="cardForm"
              onSubmit={() => {
                context.postGiftCardPurchase(this.props.history, this.props.relativePath);
              }}
            >

              {/*Review Section*/}
              <div id="review" className="bottomSpacer">
                <Header as="h3">Review</Header>
                <div className="subFormSection">

                  <Card
                    className='cardDesign'
                  >
                    <Card.Content>
                      <div
                        style={{
                          backgroundImage: `url("${this.apiURI}${context.state.selectedCardDesign.mainCardImage}")`,
                          position: 'absolute',
                          top: '0',
                          bottom: '0',
                          left: '0',
                          right: '0',
                          backgroundPositionX: '50%',
                          backgroundPositionY: '50%',
                          backgroundSize: 'cover',
                          borderRadius: 'inherit',
                        }}
                      ></div>

                    </Card.Content>
                  </Card>

                  <div className='finalRecipientDetails'>
                    <h5>{"To: " + context.state.recipientFirstName + " " + context.state.recipientLastName}</h5>
                    <h5>{"From: " + context.state.senderFirstName + " " + context.state.senderLastName}</h5>
                    <h5>{"Value: $" + (context.state.selectedAmountOption ? context.state.selectedAmountOption : context.state.customAmount)}</h5>
                    <h5>{"Delivery: " + (context.state.immediateDelivery ? new moment() : moment.tz(context.state.scheduleDeliveryDayTime.format("YYYY-MM-DD ") + context.state.timeOfDay, 'YYYY-MM-DD HH:mm', context.state.timeZone)).format("MMMM DD, YYYY [at] ha z")}</h5>
                    {context.state.isPromoActive ?
                      <h5>$10 Coupon Issued</h5>
                      : null
                    }
                  </div>
                  <Button
                    id="reviewEdit"
                    className="donatosRedButton"
                    content='Edit'
                    onClick={() => this.props.history.push(this.props.relativePath + '/card-details')}
                  />
                </div>

              </div>

              {/*Payment/Billing Section*/}
              <div id="paymentBillingSection">
                {/*Payment Information*/}
                <div id="payment" className="bottomSpacer">
                  <Header as="h3">Payment</Header>
                  <div className="subFormSection">

                    <div id='cardTypeDiv'>
                      <img src="/imgs/cc/mastercard.png" alt="CCMastercard"/>
                      <img src="/imgs/cc/visa.png" alt="CCVisa"/>
                      <img src="/imgs/cc/discover.png" alt="CCDiscover"/>
                      <img src="/imgs/cc/amex.png" alt="CCAmex"/>
                    </div>

                    <Form.Group id="cardNumCvv">
                      <Form.Field>
                        <label className="addedRequired" for="ccnumber">Number</label>
                        <Input
                          fluid
                          id="cc-number"
                          type="text"
                          name="cc-number"
                          autocomplete="cc-number"
                          value={context.state.billingCardNumber}
                          onChange={(event) => {
                            let cleaned = ('' + event.target.value).replace(/\D/g, '');
                            context.updateContext({billingCardNumber: cleaned})
                          }}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label className="addedRequired">CVV</label>
                        <Input
                          fluid
                          id="cc-csc"
                          type="text"
                          name="cc-csc"
                          autocomplete="cc-csc"
                          value={context.state.billingCVV}
                          onChange={(event) => {
                            context.updateContext({billingCVV: event.target.value})
                          }}
                        />
                      </Form.Field>
                    </Form.Group>

                    <Form.Group id="monthYear">
                      <Form.Field>
                        <label className="addedRequired">Month</label>
                        <Dropdown
                          fluid
                          search
                          id="cc-exp-month"
                          name="cc-exp-month"
                          autocomplete="cc-exp-month"
                          selection
                          value={context.state.billingMonth}
                          options={availableMonths}
                          onChange={(event, data) => {
                            context.updateContext({billingMonth: data.value})
                          }}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label className="addedRequired">Year</label>
                        <Dropdown
                          fluid
                          search
                          id="cc-exp-year"
                          name="cc-exp-year"
                          autocomplete="cc-exp-year"
                          selection
                          value={context.state.billingYear}
                          options={availableYears}
                          onChange={(event, data) => {
                            context.updateContext({billingYear: data.value})
                          }}
                        />
                      </Form.Field>
                    </Form.Group>
                  </div>
                </div>

                {/*Billing Information*/}
                <div id="billing" className="bottomSpacer">
                  <Header as="h3">Billing</Header>
                  <div className="subFormSection">
                    <Form.Group id="billingFirstLast">
                      <Form.Field>
                        <label className="addedRequired">First Name</label>
                        <Input
                          fluid
                          type="text"
                          id="cc-given-name"
                          name="cc-given-name"
                          autocomplete="cc-given-name"
                          value={context.state.billingFirstName}
                          onChange={(event) => {
                            context.updateContext({billingFirstName: event.target.value})
                          }}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label className="addedRequired">Last Name</label>
                        <Input
                          fluid
                          type="text"
                          id="cc-family-name"
                          name="cc-family-name"
                          autocomplete="cc-family-name"
                          value={context.state.billingLastName}
                          onChange={(event) => {
                            context.updateContext({billingLastName: event.target.value})
                          }}
                        />
                      </Form.Field>
                    </Form.Group>

                    <Form.Field>
                      <label className="addedRequired">Email</label>
                      <Input
                        fluid
                        type="text"
                        id="email"
                        name="email"
                        autocomplete="email"
                        value={context.state.billingEmail}
                        onChange={(event) => {
                          context.updateContext({billingEmail: event.target.value})
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label className="addedRequired">Phone</label>
                      <Input
                        fluid
                        type="tel"
                        id="tel-national"
                        name="tel-national"
                        autocomplete="tel-national"
                        value={context.state.billingPhone}
                        onChange={(event) => {
                          context.updateContext({
                            billingPhone: this.formatPhoneNumber(event.target.value)
                          })
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label className="addedRequired">Address</label>
                      <Input
                        fluid
                        type="text"
                        id="address-line1"
                        name="address-line1"
                        autocomplete="address-line1"
                        value={context.state.billingAddress}
                        onChange={(event) => {
                          context.updateContext({billingAddress: event.target.value})
                        }}
                      />
                    </Form.Field>
                    <Form.Input
                      fluid
                      type="text"
                      id="address-line2"
                      name="address-line2"
                      autocomplete="address-line2"
                      label='Address Line 2'
                      value={context.state.billingAddress2}
                      onChange={(event) => {
                        context.updateContext({billingAddress2: event.target.value})
                      }}
                    />
                    <Form.Field>
                      <label className="addedRequired">City</label>
                      <Input
                        fluid
                        type="text"
                        id="address-level2"
                        name="address-level2"
                        autocomplete="address-level2"
                        value={context.state.billingCity}
                        onChange={(event) => {
                          context.updateContext({billingCity: event.target.value})
                        }}
                      />
                    </Form.Field>

                    <Form.Group id="monthZip">
                      <Form.Field>
                        <label className="addedRequired">State</label>
                        <Dropdown
                          fluid
                          search
                          type="text"
                          id="address-level1"
                          name="address-level1"
                          autocomplete="address-level1"
                          selection
                          value={context.state.billingState}
                          options={availableStates}
                          onChange={(event, data) => {
                            context.updateContext({billingState: data.value})
                          }}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label className="addedRequired">Zip Code</label>
                        <Input
                          fluid
                          type="text"
                          id="postal-code"
                          name="postal-code"
                          autocomplete="postal-code"
                          value={context.state.billingZip}
                          onChange={(event) => {
                            context.updateContext({billingZip: event.target.value})
                          }}
                        />
                      </Form.Field>
                    </Form.Group>
                  </div>
                </div>

                <Button
                  type="submit"
                  content='Submit Order'
                  className="donatosRedButton"
                  style={{margin: 'auto auto 30px 10px'}}
                  loading={context.state.isPostingCard}
                  disabled={!context.validatePaymentForm()}
                />

                {!context.state.purchaseError ? null :
                  <Segment className="errorSegment">
                    <h4>Error</h4>
                    <p>{context.state.purchaseError}</p>
                    <h4>Please try again.</h4>
                  </Segment>
                }

                {/*Missing required field popup - Mobile*/}
                {context.validatePaymentForm() ? null :
                  <Segment className="missingContent">
                    <Header as="h5" content="Missing:"/>
                    <div horizontal className="missingContentDiv">
                      {context.validatePaymentFormItem("billingCardNumber") ? null :
                        <h6>Card Number</h6>
                      }
                      {context.validatePaymentFormItem("billingCVV") ? null :
                        <h6>CVV</h6>
                      }
                      {context.validatePaymentFormItem("billingMonth") ? null :
                        <h6>Exp. Month</h6>
                      }
                      {context.validatePaymentFormItem("billingYear") ? null :
                        <h6>Exp. Year</h6>
                      }
                      {context.validatePaymentFormItem("billingFirstLast") ? null :
                        <h6>Billing First Name</h6>
                      }
                      {context.validatePaymentFormItem("billingLastName") ? null :
                        <h6>Billing Last Name</h6>
                      }
                      {context.validatePaymentFormItem("billingEmail") ? null :
                        <h6>Valid Billing Email</h6>
                      }
                      {context.validatePaymentFormItem("billingPhone") ? null :
                        <h6>Billing Phone Number</h6>
                      }
                      {context.validatePaymentFormItem("billingAddress") ? null :
                        <h6>Billing Address</h6>
                      }
                      {context.validatePaymentFormItem("billingCity") ? null :
                        <h6>Billing City</h6>
                      }
                      {context.validatePaymentFormItem("billingState") ? null :
                        <h6>Billing State</h6>
                      }
                      {context.validatePaymentFormItem("billingZip") ? null :
                        <h6>Billing Zip Code</h6>
                      }
                    </div>
                  </Segment>
                }

              </div>

            </Form>


          </Segment>
        )}
      </GiftCardContext.Consumer>
    )
  }
}

export default withRouter(ReviewPage);
