import React, {Component} from "react";
import { Card, Container, Icon, Label } from "semantic-ui-react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchDollar } from '@fortawesome/free-solid-svg-icons'
import {withRouter} from "react-router";

class Home extends Component {

  render = () => {
    return (
      <Container textAlign='center' style={{marginTop: '25px'}}>
        <div className="landingPage">
          <Card
            className="landPageCard"
            style={{
              border: "1px solid rgb(219, 40, 40)"
            }}
            onClick={() => {
              this.props.history.push(this.props.relativePath + '/card-details');
            }}
          >
            <Card.Content>
              <Icon name="credit card" size="huge" color="black"/>
              <Label content="BUY AN E-CARD!" color="red"/>
            </Card.Content>
          </Card>

          <Card
            className="landPageCard"
            style={{
              border: "1px solid rgb(181, 204, 24)"
            }}
            onClick={() => {
              this.props.history.push(this.props.relativePath + '/balance-inquiry');
            }}
          >
            <Card.Content>
              <FontAwesomeIcon icon={faSearchDollar}/>
              <Label content="SEE YOUR BALANCE" color="olive"/>
            </Card.Content>
          </Card>
        </div>
      </Container>
    )
  }
}

export default withRouter(Home);
