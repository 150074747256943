import React, { Component } from 'react';
import { Menu, Sidebar } from "semantic-ui-react";
import { withRouter } from 'react-router-dom';

class SideBarNavigation extends Component {


  render() {

    return (
      <Sidebar.Pushable className="sidebarPushable">
        <Sidebar
            borderless
            vertical
            as={Menu}
            key="red"
            id="donatosGiftCardMenu"
            className="menuSidebar"
            direction="left"
            animation='overlay'
            onHide={this.props.closeNavMenu}
            visible={this.props.isNavMenuVisible}
        >

          <div className="overflow__header">
            <a href="https://www.donatos.com/" className="logo">
              <img src="/imgs/logo-white.svg" style={{height: "inherit"}} alt="Donatos Logo - Every piece is important. Click to navigate to the home page."/>
            </a>

            <a onClick={() => this.props.closeNavMenu()} className="nav-toggle -close close-toggle" aria-label="Close Extended Navigation">
              <span>Close Navigation</span>
            </a>
          </div>

          <div className="sidebarContent">

            {/*SideBar Copy of HeaderMenu*/}
            <div className="copyOfHeader">
              <Menu.Item as='a' href="https://www.donatos.com/rewards">REWARDS & OFFERS</Menu.Item>
              <Menu.Item as='a' href="https://www.donatos.com/menu">MENU & ORDER</Menu.Item>
              <Menu.Item as='a' href="https://www.donatos.com/location">LOCATIONS</Menu.Item>
              <Menu.Item as='a' href="https://www.donatos.com/catering">CATERING</Menu.Item>
              <Menu.Item as='a' href="https://www.donatospizzafranchise.com/">OWN A DONATOS »</Menu.Item>
            </div>

            {/*SideBar Menu*/}
            <div className="actualSidebarMenu">
              <Menu.Item as='a' href="https://www.mydonatosfeedback.com/Survey.aspx">Customer Service</Menu.Item>
              <Menu.Item as='a' href="https://www.donatos.com/giftcards">Gift Cards</Menu.Item>
              <Menu.Item as='a' href="https://www.donatos.com/givingback">Giving Back</Menu.Item>
              <Menu.Item as='a' href="https://www.donatos.com/nutrition">Nutrition</Menu.Item>
              <Menu.Item as='a' href="https://www.donatos.com/careers">Careers</Menu.Item>
            </div>
          </div>

          <div className="overflow__account">
    				<h2 className="h -sm -upper">My Account</h2>
  					<a href="https://www.donatos.com/login" className="btn -primary -small -border">Log In</a>
    			</div>
        </Sidebar>

        <Sidebar.Pusher dimmed={this.props.isNavMenuVisible}>
            {this.props.children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      )
    }
};

export default withRouter(SideBarNavigation);
