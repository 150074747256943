import moment from "moment-timezone";

export default class APIService {
  static serverUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";
  static clientId = process.env.REACT_APP_SOLSYSAUTH_CLIENT_ID || "RUNTIME_REPLACE_REACT_APP_SOLSYSAUTH_CLIENT_ID";
  static captchaKey = process.env.REACT_APP_CAPTCHA_KEY || "RUNTIME_REPLACE_REACT_APP_CAPTCHA_KEY";

  static getActiveCardDesigns = async function() {
    return fetch(APIService.serverUrl + "/gift-card-design/active", {
      method: "POST",
      headers: {
        clientId: APIService.clientId
      },
      credentials: "include"
    })
      .then(result => {
        return result.json();
      })
  }

  static getPromoPeriod = async function() {
    return fetch(APIService.serverUrl + "/promo-period/active", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        clientId: APIService.clientId
      },
      credentials: "include",
    })
      .then(result => {
        return result.json();
      })
  }

  static createGiftCard = async function(state) {
    let billingCardNumber = state.billingCardNumber;
    let formattedCard = billingCardNumber.replace(/[^\d]/g, '');

    let billingPhone = state.billingPhone;
    let formattedPhone = billingPhone.replace(/[^\d]/g, '');
    
    return new Promise(resolve => window.grecaptcha.ready(resolve))
      .then(() => {
        return window.grecaptcha.execute(APIService.captchaKey, {action: 'submit'})
      })
      .then((token) => {
        return fetch(APIService.serverUrl + "/gift-card", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            clientId: APIService.clientId,
            grt: token
          },
          credentials: "include",
          body: JSON.stringify({
            amount: Number(state.hasSelectedCustomAmount ? state.customAmount : state.selectedAmountOption),
            giftCardDesign: state.selectedCardDesign._id,
            scheduledDelivery: state.immediateDelivery ?
              new moment() : moment.tz(state.scheduleDeliveryDayTime.format("YYYY-MM-DD ") + state.timeOfDay, 'YYYY-MM-DD HH:mm', state.timeZone),
            scheduledDeliveryTimezone: state.immediateDelivery ? moment.tz.guess() : state.timeZone,
            ...state.isPromoActive && {sendPromotionToRecipient: state.sendPromotionToRecipient},
            payment: {
              cardNumber: formattedCard,
              cvv: state.billingCVV,
              expirationMonth: state.billingMonth,
              expirationYear: state.billingYear
            },
            billingInfo: {
              firstName: state.billingFirstName,
              lastName: state.billingLastName,
              email: state.billingEmail,
              phone: formattedPhone,
              address: state.billingAddress,
              address2: state.billingAddress2,
              city: state.billingCity,
              state: state.billingState,
              zip: state.billingZip
            },
            recipientInfo: {
              firstName: state.recipientFirstName,
              lastName: state.recipientLastName,
              email: state.recipientEmail,
              message: state.message
            },
            sender: {
              firstName: state.senderFirstName,
              lastName: state.senderLastName
            }
          })
        })
      })
      // .then((response) => {
      //   if(response.ok) {
      //     return response.json();
      //   } else {
      //     return null;
      //   }
      // })

  }

  static getInquiry = async function(state) {
    return new Promise(resolve => window.grecaptcha.ready(resolve))
      .then(() => {
        return window.grecaptcha.execute(APIService.captchaKey, {action: 'submit'})
      })
      .then((token) => {
        return fetch(APIService.serverUrl + "/gift-card/balance", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            clientId: APIService.clientId,
            grt: token
          },
          credentials: "include",
          body: JSON.stringify({
            cardNumber: state.inquiryCardNumber,
            pinNumber: state.inquiryCardPin,
          })
        })
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw response;
        }
      })
  }
}
