import React, {Component} from "react";
import { Button, Card, Dropdown, Form, Header, Input, Label, Modal, Segment, Checkbox } from "semantic-ui-react";
import {withRouter} from "react-router";
import {GiftCardContext} from "../Context/GiftCardContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';



class CardDetails extends Component {

  componentDidMount() {
    this.scrollToTop();
  };

  scrollToTop = () => {
    window.scrollTo(0, 0)
  };

  contextRef = React.createRef();

  apiURI = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

  render = () => {
    return (
      <GiftCardContext.Consumer>
        {(context) => (
          <div>

            {!context.state.isPromoActive ? null :
              <Segment className="couponSegment">
                <p id="couponHeader">{context.state.promoName}</p>

                <p id="couponContent">{context.state.promoDescription ? context.state.promoDescription : "" }</p>
              </Segment>
            }

            <Segment className="pageSegment">
              <Form className="cardForm" autocomplete="off">
                <p style={{fontSize: '16px', fontFamily: 'Cabin, sans-serif', marginTop: '0px'}}>Donatos gift cards can only be used at participating Donatos locations. Other restaurants serving Donatos Pizza (such as Red Robin) will not take them.</p>

                {/*Choose a Design*/}
                <div id="chooseADesign" className="bottomSpacer">
                  <Header as="h3">Choose a Design</Header>
                  {context.state.isFetchingCardDesigns ?
                    <Segment loading padded='very' basic>

                    </Segment>
                    :
                    <div id="cardMapping">
                      {context.state.cardDesigns.map((card) => {
                        return (
                          <Card
                            className={'cardDesign' + (card._id === context.state.selectedCardDesign._id ? ' selectedCard' : '')}
                            key={card._id}
                          >
                            <Card.Content
                              onClick={() => {
                                context.updateContext({selectedCardDesign: card})
                              }}>
                              <div
                                style={{
                                  backgroundImage: `url("${this.apiURI}${card.mainCardImage}")`,
                                  position: 'absolute',
                                  top: '0',
                                  bottom: '0',
                                  left: '0',
                                  right: '0',
                                  backgroundPositionX: '50%',
                                  backgroundPositionY: '50%',
                                  backgroundSize: 'cover',
                                  ...card._id === context.state.selectedCardDesign._id && {
                                    boxShadow: "0 0 8px 5px rgb(107, 118, 118)"
                                  }
                                }}
                              ></div>
                            </Card.Content>
                          </Card>
                        )
                      })}
                    </div>
                  }
                </div>

                {/*Choose an Amount*/}
                <div id="chooseAmount" className="bottomSpacer">
                  <Header as="h3">Choose an Amount</Header>
                  <div className="optionsWrapper">
                    <div/>
                    <div id="chooseAmountButtons">
                      {context.amountOptions.map(amountOption => (
                        <Button
                          content={"$" + amountOption}
                          onClick={(event) => {
                            context.updateContext({
                              hasSelectedCustomAmount: false,
                              customAmount: null,
                              selectedAmountOption: amountOption
                            })
                          }}
                          className={amountOption === context.state.selectedAmountOption && !context.state.hasSelectedCustomAmount ? "donatosRedButton" : ""}
                        />
                      ))}
                      <Button
                        content="Other"
                        onClick={() => {
                          document.getElementById('otherInput').focus()
                          context.updateContext({
                            hasSelectedCustomAmount: true,
                            selectedAmountOption: null,
                          })
                        }}
                        className={context.state.hasSelectedCustomAmount ? "donatosRedButton" : ""}
                      />
                    </div>
                    <div className="otherInputWrapper">
                      <Form.Input
                        id="otherInput"
                        label="Amount"
                        value={"$" + (context.state.customAmount || "")}
                        onChange={(event) => {
                          context.updateContext({
                            hasSelectedCustomAmount: true,
                            selectedAmountOption: null,
                            customAmount: Math.ceil(Number(event.target.value.replace('$', '')))
                          });
                        }}
                      />
                      <Label
                        basic
                        style={{
                          ...!context.state.hasSelectedCustomAmount ||
                            !context.state.customAmount ||
                            context.validateCardDetail('cardAmount')
                            ? {visibility: 'hidden'} : {}
                        }}
                        color='red'
                        pointing="above"
                        content={
                          context.state.customAmount >= 5 ?
                          context.state.customAmount <= 100 ?
                          "Good" : "Must be $100 or less" : "Must be $5 or more"
                        }
                      />
                    </div>
                  </div>
                </div>

                {/*Coupon Recipient*/}
                {context.state.isPromoActive && context.state.selectedAmountOption >= 50 || (context.state.hasSelectedCustomAmount && context.state.customAmount >= 50) ?
                  <div className="bottomSpacer">
                    <Header as="h3">Coupon Recipient</Header>
                    <div style={{marginTop: "-6px"}}>
                      <p style={{color: "#696f7b", fontFamily: "OpenSans, Arial, sans-serif", fontSize: "12px", letterSpacing: "-0.15px", lineHeight: "14px", textAlign: "left", marginLeft: "8px"}}>A coupon code will be sent by separate email. Select "Me" to receive it yourself or "Friend" to gift it to the eGift Card recipient.</p>
                      <div style={{display: "flex", justifyContent: "center"}}>
                        <Button
                          content="Me"
                          className={!context.state.sendPromotionToRecipient ? "selectedCouponButton" : "couponButton"}
                          onClick={() => {
                            context.updateContext({sendPromotionToRecipient: false})
                          }}
                          style={{marginRight: "0px"}}
                        />
                        <p style={{color: "#454545", fontFamily: "OpenSans, Arial, sans-serif", fontSize: "24px", letterSpacing: "-0.3px", lineHeight: "29px", textAlign: "center", fontWeight: "bold", margin: "auto 36px"}}>$10.00</p>
                        <Button
                          content="Friend"
                          className={context.state.sendPromotionToRecipient ? "selectedCouponButton" : "couponButton"}
                          onClick={() => {
                            context.updateContext({sendPromotionToRecipient: true})
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  : null
                }


                {/*Recipient Information*/}
                <div id="recepientInformation" className="bottomSpacer">
                  <Header as="h3">Recipient Information</Header>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label className="addedRequired">First Name</label>
                      <input
                        fluid
                        autocomplete="new"
                        value={context.state.recipientFirstName}
                        onChange={(event) => {
                          context.updateContext({recipientFirstName: event.target.value})
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label className="addedRequired">Last Name</label>
                      <Input
                        fluid={true}
                        autocomplete="off"
                        value={context.state.recipientLastName}
                        onChange={(event) => {
                          context.updateContext({recipientLastName: event.target.value})
                        }}
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Field>
                    <label className="addedRequired">Email Address</label>
                    <Input
                      fluid={true}
                      autocomplete="off"
                      value={context.state.recipientEmail}
                      onChange={(event) => {
                        context.updateContext({recipientEmail: event.target.value})
                      }}
                    />
                  </Form.Field>

                </div>

                {/*Add a Message*/}
                <div id="addAMessage" className="bottomSpacer">
                  <Header as="h3">Add a Message</Header>
                  <div className="subFormSection">
                    <Form.TextArea
                      label='Message'
                      value={context.state.message}
                      onChange={(event) => {
                        context.updateContext({message: event.target.value})
                      }}
                    />
                  </div>

                </div>

                {/*Sender Information*/}
                <div id="senderInformation" className="bottomSpacer">
                  <Header as="h3">Sender Information</Header>
                  <Form.Group widths="equal" id="senderFirstLastFields">
                    <Form.Field>
                      <label className="addedRequired">First Name</label>
                      <Input
                        fluid={true}
                        value={context.state.senderFirstName}
                        onChange={(event) => {
                          context.updateContext({senderFirstName: event.target.value})
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label className="addedRequired">Last Name</label>
                      <Input
                        fluid={true}
                        value={context.state.senderLastName}
                        onChange={(event) => {
                          context.updateContext({senderLastName: event.target.value})
                        }}
                      />
                    </Form.Field>
                  </Form.Group>
                </div>

                {/*Choose a Delivery Date*/}
                <div id="chooseADeliveryDate" className="bottomSpacer">
                  <Header as="h3">Choose a Delivery Day</Header>
                  <div className="subFormSection" style={{display: 'flex', flexDirection: 'column'}}>

                    <Button.Group>
                      <Button
                        content="Send Now"
                        color="red"
                        basic={!context.state.immediateDelivery}
                        active={context.state.immediateDelivery}
                        onClick={() => context.updateContext({immediateDelivery: true})}
                      />
                      <Button
                        content="Send Later"
                        color="red"
                        basic={context.state.immediateDelivery}
                        active={!context.state.immediateDelivery}
                        onClick={() => context.updateContext({immediateDelivery: false})}
                      />
                    </Button.Group>

                    <Segment
                      className={context.state.immediateDelivery ? "hidden" : ""}
                    >
                      <DatePicker
                        id="datePicker"
                        name="deliveryDate"
                        dateFormat="MM/dd/yyyy"
                        showDisabledMonthNavigation
                        minDate={new moment().add(1, 'hours').startOf('day').toDate()}
                        selected={context.state.scheduleDeliveryDayTime.toDate()}
                        onChange={(date) => {
                          context.updateContext({
                            scheduleDeliveryDayTime: date && date !== "" ? new moment(date) : new moment()
                          })
                        }}
                      />
                      <Form.Group>
                        <Form.Field style={{minWidth: '195px'}}>
                          <label>Time of Day</label>
                          <Dropdown
                            fluid
                            id="timeOfDay"
                            name="timeOfDay"
                            selection
                            value={context.state.timeOfDay}
                            options={context.deliveryTimeOptions.filter(option => {
                              return moment.tz(context.state.scheduleDeliveryDayTime.format("YYYY-MM-DD ") + option.value, 'YYYY-MM-DD HH:mm', context.state.timeZone).isAfter()
                              // if the day in not today && the hour is in the past.
                            })}
                            onChange={(event, data) => {
                              context.updateContext({timeOfDay: data.value})
                            }}
                          />
                        </Form.Field>
                        <Form.Field style={{minWidth: '195px'}}>
                          <label>Time Zone</label>
                          <Dropdown
                            fluid
                            id="timeZone"
                            name="timeZone"
                            selection
                            value={context.state.timeZone}
                            options={context.deliveryTimeZones}
                            onChange={(event, data) => {
                              context.updateContext({timeZone: data.value})
                            }}
                          />
                        </Form.Field>
                      </Form.Group>
                    </Segment>
                  </div>
                </div>

                <Button
                  content='Continue to Checkout'
                  className="donatosRedButton"
                  disabled={!context.validateCardDetailForm()}
                  onClick={() => this.props.history.push(this.props.relativePath + '/review')}
                />

                {/*Missing required */}
                {context.validateCardDetailForm() ? null :
                  <Segment className="missingContent">
                    <Header as="h5" content="Missing:"/>
                    <div horizontal className="missingContentDiv">
                      {context.validateCardDetail('selectedCardDesign') ? null :
                        <h6>Select a Card Design</h6>
                      }
                      {context.validateCardDetail('cardAmount') ? null :
                        <h6>Select a Card Amount</h6>
                      }
                      {context.validateCardDetail('recipientFirstName') ? null :
                        <h6>Recipient First Name</h6>
                      }
                      {context.validateCardDetail('recipientLastName') ? null :
                        <h6>Recipient Last Name</h6>
                      }
                      {context.validateCardDetail('recipientEmail') ? null :
                        <h6>Valid Recipient Email</h6>
                      }
                      {context.validateCardDetail('senderFirstName') ? null :
                        <h6>Sender First Name</h6>
                      }
                      {context.validateCardDetail('senderLastName') ? null :
                        <h6>Sender Last Name</h6>
                      }
                    </div>
                  </Segment>
                }
              </Form>
            </Segment>
          </div>

        )}
      </GiftCardContext.Consumer>
    )
  }
}

export default withRouter(CardDetails);
