import React, {Component} from "react";
import { Button, Container, Icon, Segment } from "semantic-ui-react";
import {withRouter} from "react-router";
import {GiftCardContext} from "../Context/GiftCardContext";
import moment from 'moment';

class OrderConfirmation extends Component {

  componentDidMount() {
    this.scrollToTop();
  };

  scrollToTop = () => {
    window.scrollTo(0, 0)
  };

  render = () => {
    return (
      <GiftCardContext.Consumer>
        {(context) => {
          if (!context.state.confirmation) {
            this.props.history.replace('/')
          } else {

            let confirmation = context.state.confirmation;
            let amount = confirmation.approvedAmount && confirmation.approvedAmount.toFixed(2);
            let recipient = confirmation.recipient || {};
            let delivery = confirmation.scheduledDelivery ? moment(confirmation.scheduledDelivery).format("MMMM DD, YYYY [at] h:mm a") : "NA";

            return (
              <div id="orderConfirmation">
                <Container textAlign='center'>
                  <div className='confirmationDiv'>

                    <h2>Thank you for your Gift Card purchase!</h2>

                    <h4>Here is your eGift Card purchase confirmation code: <font>{confirmation.confirmationCode}</font></h4>

                    <Segment className="detailSegment">
                      <h4>Amount: <font>${amount}</font></h4>
                      <h4>Recipient: <font>{`${recipient.firstName} ${recipient.lastName} (${recipient.email})`}</font></h4>
                      <h4>Delivery: <font>{delivery}</font></h4>
                    </Segment>

                    <h4>A Donatos gift card will be emailed to the recipient on the day and time selected. Please allow for a small amount of processing time due to email volume.</h4>
                  </div>
                  <a href="https://www.donatos.com">
                    <Button
                      content='Return to Donatos.com'
                      style={{backgroundColor: '#E51B24', color: '#fff'}}
                    />
                  </a>

                </Container>
              </div>
            )
          }
        }}
      </GiftCardContext.Consumer>

    )
  }
}

export default withRouter(OrderConfirmation);
