import React, {Component} from "react";
import { Button, Container, Form, Input } from "semantic-ui-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchDollar } from '@fortawesome/free-solid-svg-icons'
import {withRouter} from "react-router";
import {GiftCardContext} from "../Context/GiftCardContext";

class BalanceInquiry extends Component {

  componentDidMount() {
    this.scrollToTop();
  };

  scrollToTop = () => {
    window.scrollTo(0, 0)
  };

  render = () => {
    return (
      <GiftCardContext.Consumer>
        {(context) => (
          <div id="balanceInquiry">
            <Container textAlign='center'>
              <div className="inquiryHeader">
                <FontAwesomeIcon icon={faSearchDollar}/>
                <h4>BALANCE INQUIRY</h4>
              </div>
              <h3>Check Your Gift Card Balance!</h3>
              <h5>To see the remaining balance on your Donatos Gift Card enter the 19-digit card number and the 4-digit PIN number.</h5>
              <Form className="inquiryForm" onSubmit={() => {
                context.getInquiry();
              }}>
                <Form.Field>
                  <label className="inquiryFormLabel">Card Number (19 digits)</label>
                  <Input
                    fluid
                    value={context.state.inquiryCardNumber}
                    onChange={(event) => {
                      context.updateContext({inquiryCardNumber: event.target.value})
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label className="inquiryFormLabel">PIN (4 digits)</label>
                  <Input
                    fluid
                    value={context.state.inquiryCardPin}
                    onChange={(event) => {
                      context.updateContext({inquiryCardPin: event.target.value})
                    }}
                  />
                </Form.Field>
                <Button
                  type="submit"
                  content="Check Balance"
                  disabled={!context.validateInquiryForm()}
                  loading={context.state.isFetchingCardInquiry}
                />
              </Form>

              {!context.state.isValidInquiry ? null :
                <div className="cardBalanceDiv">
                  <h3>{`CARD BALANCE: $${context.state.inquiryAmount}`}</h3>
                  <h4>Great news! If you're hungry <a href="https://www.donatos.com">Order Online</a> now.</h4>
                </div>
              }

              {!context.state.isInvalidInquiry ? null :
                <div className="invalidDiv">
                  <h4>NOT A VALID CARD/PIN NUMBER</h4>
                  <h4>Check you Card & PIN numbers and try again.</h4>
                </div>
              }

              {!context.state.hasInquiryBeenAttempted ? null :
                <div className="helpDiv">
                  <h4>If you need help with your Gift Card let us know!</h4>
                  <h3>Call 1-800-DONATOS (366-2867)</h3>
                </div>
              }
            </Container>
          </div>
        )}
      </GiftCardContext.Consumer>

    )
  }
}

export default withRouter(BalanceInquiry);
