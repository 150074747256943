import React, {Component} from "react";
import { Button, Container, Segment } from "semantic-ui-react";
import {withRouter} from "react-router";
import {GiftCardContext} from "../Context/GiftCardContext";
import moment from 'moment';

class ErrorHandling extends Component {

  componentDidMount() {
    this.scrollToTop();
  };

  scrollToTop = () => {
    window.scrollTo(0, 0)
  };

  render = () => {
    return (
      <GiftCardContext.Consumer>
        {(context) => (
          <div id="errorHandling">
            <Container textAlign='center'>
              <div className="errorHandlingContent">
                <h2>We encountered an issue:</h2>
                <Segment>
                  <h4>{`Reference ID: ${context.state.errorRefId || "N/A"}`}</h4>
                  <h3>Call <font>1-800-DONATOS (366-2867)</font> to report the issue.</h3>
                  <p>Trying again may result in multiple payment transactions to occur.</p>
                </Segment>
              </div>
            </Container>
          </div>
        )}
      </GiftCardContext.Consumer>

    )
  }
}

export default withRouter(ErrorHandling);
